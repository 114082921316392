/* eslint-disable react/prop-types */
import React, { memo, useCallback, useEffect, useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import Text from "../../../../components/Wrapper/Text";
import Button from "../../../../components/button/Button";
import playTExt from "../../../../assets/Play.svg";
import {
  OnRoomListing,
  OnRoomPropertiesChange,
  RoomListing,
  getRoomProperties,
  localSetRoomProperties,
  onGetRoomProperties,
  onLocalGetRoomProperties,
  onRaiseEvent,
  raiseEvent,
  setRoomProperties,
} from "../../../../constants/socketKeys";
import {
  publish,
  subscribe,
  unsubscribe,
} from "../../../../CustomEvents/event";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentRoomPlayers,
  setCurrentRoomProperties,
  setGameObj,
  setSingleLobbyData,
  setUserData,
} from "../../../../feature/slice/gameSlice";

import Avatar from "../../../../assets/avatar.png";
import { useHistory } from "react-router-dom";
import { socket } from "../../../../socket/socket";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { delay, showToast } from "../../../../utils";
import { setSessionStorageItem } from "../../../../utils/sessionStorageUtils";
import ShareButton from "../ShareButton";
// import { RWebShare } from "react-web-share";
import Spinner from "../../../../components/Spinner/Spinner";
function RoomCode({ code }) {
  return (
    <div
      title="click to copy"
      className="lg:w-7/12 text-center text-xl bg-[#ffc226] cursor-pointer border-none flex items-center justify-center gap-2 py-2 px-6 outline outline-[#673F15] rounded-sm text-[#552600] drop-shadow-lg font-bolder"
    >
      {code}
      <CopyToClipboard
        text={code}
        onCopy={() => showToast("success", "code coppied!")}
      >
        <svg
          width="23"
          height="23"
          fill="none"
          stroke="#552600"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="13" height="13" x="9" y="9" rx="2" ry="2"></rect>
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
        </svg>
      </CopyToClipboard>
    </div>
  );
}

export const AddPlayer = ({ player }) => {
  //   // console.log("player", player);
  return (
    <div className="row-span-2 text-center w-16 h-16 lg:w-20 lg:h-20">
      <div className="hover:opacity-70 transition-all cursor-pointer bg-[#fff] px-2 py-4 rounded-md outline outline-2 outline-yellow-400  w-full h-full flex items-center justify-center">
        {player ? (
          <div className="w-12 h-12">
            <img src={Avatar} alt="Avatar" width="100%" />
          </div>
        ) : (
          <svg
            width="70"
            height="70"
            fill="#ffba24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4Zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6Zm1 8c0-2.66 5.33-4 8-4s8 1.34 8 4v2H7v-2Z"
              clipRule="evenodd"
            ></path>
          </svg>
        )}
      </div>
      <span className="text-white text-xs">{player?.nickname || null}</span>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
function PRoomPlayerModal({ roomCode, roomPassword, modalShow, onClose,setPlayers,players,auth }) {
  const [singleLobbyData2, setSingleLobby] = useState(null);
  
  const [properties, setProperties] = useState(null);
  const [loading , setLoading] = useState(false);
  const { gameObj, userData,roomProperties, singleLobbyData,currentRoomPlayers } =
  useSelector((state) => state.game);
  const dispatch = useDispatch();
  const navigate = useHistory();



  const onHandleGetRoomProperties = useCallback(
    (data) => {
      const roomData = data.detail.data;
      // debugger;
      console.warn("onHandleGetRoomProperties roomData 1", data.detail);
      if(roomData) {
        dispatch(setCurrentRoomProperties({ ...roomData, roomName: roomCode }));
        setProperties(roomData);
      }
    },
    [dispatch, roomCode]
  );

  // ** handle Create Local Room Property Lobby ByCreator
  const handleCreateLocalRoomPropertyLobbyByCreator = useCallback(
    (userData, gameObj) => {
      publish(localSetRoomProperties, {
        roomname: roomCode,
        nickname: userData?.nickname,
        uid: userData?.uid,
        roomCustomPropertiesForLobby: {
          tokenArr: [gameObj?.token],
          boardType: gameObj.selectedBoard,
          roomType: gameObj?.feeType,
          roomAmmount: gameObj?.feeAmmount,
        },
      });
    },
    [roomCode]
  );

  //** handle Set Local Room Property ByCreator  */
  const handleSetLocalRoomPropertyByCreator = useCallback(
    (userData, gameObj) => {
      publish(setRoomProperties, {
        roomname: roomCode,
        nickname: userData?.nickname,
        uid: userData?.uid,
        roomCustomProperties: {
          maxPlayer: gameObj?.inGamePlayer,
          boardType: gameObj?.selectedBoard,
          tokenArr: [
            {
              ...userData,
              color: gameObj.token,
              userId : window?.localStorage.getItem('userID'),
              profilePic : auth?.userData?.profilePic
            },
          ],
        },
      });
    },
    [auth?.userData?.profilePic, roomCode]
  );

  useEffect(() => {
    if (userData?.isCreator && modalShow) {
      // ** publish local set room properties for lobby
      handleCreateLocalRoomPropertyLobbyByCreator(userData, gameObj);
      // ** publish local set room properties
      handleSetLocalRoomPropertyByCreator(userData, gameObj);
    }
  }, [
    gameObj,
    handleCreateLocalRoomPropertyLobbyByCreator,
    handleSetLocalRoomPropertyByCreator,
    modalShow,
    singleLobbyData,
    userData,
  ]);


  useEffect(() => {
    subscribe(onLocalGetRoomProperties, onHandleGetRoomProperties);
    return () => unsubscribe(onLocalGetRoomProperties, onHandleGetRoomProperties)
  },[])

  //** handleCreateRoomPropertyByOther */
  useEffect(() => {
    //  let timeout = setTimeout(() => {
       console.log("handleCreatRoom log******",properties,singleLobbyData,userData);
      if(properties && singleLobbyData && userData?.isCreator === false) {
        //  handleCreateRoomPropertyByOther(singleLobbyData,roomData);
        const roomName = singleLobbyData?.roomname;
        const tokenArr = properties?.tokenArr;
        const maxPlayers = roomProperties?.maxPlayers;
        //;
        if (tokenArr) {
          const myTokenData = [
            {
              ...userData,
              color: userData.color,
              userId : window?.localStorage.getItem('userID')
            },
          ];
  
          const findExistance = tokenArr?.find(
            (data) => data.uid === myTokenData[0].uid
          );
  
          console.log("handleCreatRoom log****** findExistance",findExistance,myTokenData,tokenArr);
          // if(findExistance) {
          //   debugger;
          // };
  
          if (!findExistance) {
            const updateTokneArr = [...tokenArr, ...myTokenData];
            console.log("handleCreatRoom log******2",updateTokneArr);
            //** common info object */
            const commonData = {
              roomname: roomName,
              nickname: userData?.nickname,
              uid: userData?.uid,
            };
            const RoomData = {
              ...commonData,
              roomCustomProperties: {
                ...properties,
                tokenArr: updateTokneArr,
              },
            };
            const LobbyData = {
              ...commonData,
              roomCustomPropertiesForLobby: {
                ...singleLobbyData?.roomCustomPropertiesForLobby,
                tokenArr: updateTokneArr.map((token) => token.color),
              },
            };
            // lobby Data update
            publish(localSetRoomProperties, LobbyData);
            // update room properties
            if(updateTokneArr.length <= maxPlayers) {
              publish(setRoomProperties, RoomData);
            }
          }
        }
       } 
    //  },500);

    //  return () => clearTimeout(timeout);
  },[properties,singleLobbyData,userData?.isCreator])

  // //** on room propertie change */
  const getUpdatedPropertie = useCallback(
    (data) => {
      const getUpdatedPropertie = data.detail.data;
      console.log("getUpdatedPropertie**", getUpdatedPropertie);
      dispatch(setCurrentRoomPlayers({tokenArr : getUpdatedPropertie.tokenArr}));
      dispatch(setCurrentRoomProperties(getUpdatedPropertie));
    },
    [dispatch]
  );

  //** get room properties */
  useEffect(() => {
    let timeout = null;
    // console.log("getRoomProperties request..>>>",roomCode,modalShow,userData)
    if(modalShow && userData && roomCode) {
      // //;
       setLoading(true);
       timeout = setTimeout(() => {
        publish(getRoomProperties, {
          roomname: roomCode,
          roomPassword: "abc123",
          uid: userData?.uid,
        });
        setLoading(false);
      },1000)
    }
    return () => clearTimeout(timeout);
  },[roomCode, roomPassword, userData,modalShow])

  // //** subscribe event */
  useEffect(() => {
    console.log("sub - OnRoomPropertiesChange**");
    subscribe(OnRoomPropertiesChange,getUpdatedPropertie);
    subscribe(onGetRoomProperties, onHandleGetRoomProperties);
    return () => {
      unsubscribe(OnRoomPropertiesChange,getUpdatedPropertie);
      unsubscribe(onGetRoomProperties, onHandleGetRoomProperties);
    };
  }, [getUpdatedPropertie, onHandleGetRoomProperties]);

  /**
   *  TODO - fetch room listing
   */
  useEffect(() => {
    const fetchRoomListing = () => {
      subscribe(OnRoomListing, async (data) => {
        // //;
        var rooms = data.detail.data;
        const specificLobby =
        rooms.find((curr) => curr.roomname === roomCode) || null;
        setSingleLobby(specificLobby);
        const boardType = specificLobby?.roomCustomPropertiesForLobby?.boardType;
        if (boardType) {
          dispatch(setGameObj({ selectedBoard: boardType }))
        }
        dispatch(setSingleLobbyData(specificLobby))
      });
    };
    // fetch room listing
    if (modalShow && userData?.isCreator === false) {
      publish(RoomListing);
      fetchRoomListing();
    }
    return () => {
      unsubscribe(OnRoomListing);
    };
  }, [dispatch, modalShow, roomCode, userData?.isCreator]);

  //** publish event */
  //** get Room Properties

  /** update players */
  useEffect(() => {
    if (currentRoomPlayers?.length > 0) {
      setPlayers(
        currentRoomPlayers.filter((player) => player.isCreator === false)
      );
    }
  }, [currentRoomPlayers]);

  //**startRaiseEvent */
  const startRaiseEvent = useCallback(() => {
    //** clear out session */
    setSessionStorageItem("gameSession", null);
    let redirectUrl = `/qwertyuiop/game?roomName=${roomCode}&uid=${userData?.uid}&started=false`;
    navigate.push(redirectUrl);
  }, [navigate, roomCode, userData?.uid]);

  //** startGame */
  const startGame = () => {
   if(roomProperties?.maxPlayers-1  === players.length) {
    const startGameEvent = {
      roomName: roomCode,
      EventCode: 3,
      SendTo: "All",
      CustomData: {
        sendingUser: socket.id,
        startTurn: gameObj?.token, // it a color type ,
      },
    };
    //** clear out session */
    setSessionStorageItem("gameSession", null);

    // publis event
    publish(raiseEvent, startGameEvent);
    let redirectUrl = `/qwertyuiop/game?roomName=${roomCode}&uid=${userData?.uid}&started=false`;
    navigate.push(redirectUrl);
   } else {
    showToast("error", "Can't start untill all player joines");
   }
  };

  // ** Handling all the raise events
  const handleRaisedEvents = useCallback(
    (eventData) => {
      const data = eventData.detail;
      // console.log("handleRaisedEvents>>>>", eventData);
      if (!data) return;
      const { EventCode, CustomData } = data;
      if (CustomData?.sendingUser !== socket.id) {
        if (EventCode === 3) startRaiseEvent(CustomData);
      }
    },
    [startRaiseEvent]
  );

  //** raise EventEffect */
  const raiseEventEffect = () => {
    subscribe(onRaiseEvent, handleRaisedEvents);
    return () => unsubscribe(onRaiseEvent, handleRaisedEvents);
  };

  // ** raise event useEffect
  useEffect(raiseEventEffect, [handleRaisedEvents]);

  //** grid cols */
  let gridCols = "grid-cols-3";
  if (roomProperties?.maxPlayers) {
    gridCols = `grid-cols-${roomProperties?.maxPlayers - 1 || singleLobbyData?.maxPlayers - 1}`;
  }

  console.log("players",players)

  return (
    <ModalWrapper
      visible={modalShow}
      onClose={onClose}
      customStyles={{
        width: "30%",
        height: "auto",
        maxHeight: "70%",
      }}
      title=""
      id="private-room-player-modal"
    >
      <div className="p-8 h-full  w-10/12 m-auto  flex flex-col justify-center items-center gap-5">
        {/* text */}
        <Text
          title="Your room code:"
          size="3xl"
          color="text-center text-white text-xl lg:text-3xl"
        />

        {/* room code */}
        <RoomCode code={roomCode} />

        {/* text */}
        {/* <Text
          title="Your room password:"
          size="3xl"
          color="text-center text-white"
        /> */}

        {/* room code */}
        {/* <RoomCode code={roomPassword} /> */}

        {/* text */}
        <Text
          title="Share this room code with friends and ask them to join"
          size="md"
          color="text-center text-white text-wrap"
        />

        <ShareButton roomCode={roomCode} />
        {/* players */}
        {
         loading ? <div className="flex justify-center">
          <Spinner />
         </div> : (
              <div
                className={`grid grid-rows-1 ${gridCols} justify-center ${
                  roomProperties?.maxPlayers === 4 || roomProperties?.maxPlayers === 2
                    ? "w-full"
                    : "lg:w-9/12"
                } gap-x-4 mt-5`}
              >
            
                    <>
                        {roomProperties?.maxPlayers === 2 && (
                          <AddPlayer player={players[0] || null} />
                        )}
      
                        {roomProperties?.maxPlayers === 3 && (
                          <>
                            <AddPlayer player={players[0] || null} />
                            <AddPlayer player={players[1] || null} />
                          </>
                        )}
                        
                        {roomProperties?.maxPlayers === 4 && (
                          <>
                            <AddPlayer player={players[0] || null} />
                            <AddPlayer player={players[1] || null} />
                            <AddPlayer player={players[2] || null} />
                          </>
                        )}
                    </>
              
              </div>
         )
        }
       

        {userData?.isCreator && (
          <Button
            text={<img src={playTExt} alt={playTExt} className="m-auto" />}
            className="play py-3 text-sm md:text-xl lg:w-4/12 mt-4"
            handleClick={startGame}
          />
        )}
      </div>
    </ModalWrapper>
  );
}

export default memo(PRoomPlayerModal);
